<template>
  <span>
    <v-text-field
    v-if="type=='password'"
    class="input-group--focused"
    @click:append="show = !show"
    v-model="inputVal"
    outlined
     clearable
    :type="show ? 'text' : 'password'"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :label="label"
    :rules="rules"
    :value="value ? value : ''"
  ></v-text-field>
  <v-text-field
    v-else
    class="input-group--focused"
    v-model="inputVal"
    outlined
     clearable
    :type="type"
    :label="label"
    :rules="rules"
    :value="value ? value : ''"
    :prefix="prefix"
  ></v-text-field>
  </span>
</template>

<script>
export default {
  props: ["label", "value","rules","type","prefix"],
  components: {
    //
  },
  computed:{
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
    password: null,
    show: false,
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>