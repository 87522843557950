<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (userApi.arror = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Create New Classroom</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="name"
                label="Classroom name"
                :rules="nameRules"
                type="text"
                @input="name = $event"
              ></TextField>
            </div>
            <div class="text-center pt-3">
              <SelectField
                :value="schoolYear"
                :field="selectSchoolYearField"
                @input="schoolYear = $event"
                label="School Year"
              ></SelectField>
            </div>
            <div class="d-flex">
              <div class="text-center pt-3">
              <SelectField
                :value="month"
                :field="selectMonthField"
                @input="month = $event"
                label="Month"
              ></SelectField>
            </div>
            <div class="text-center pt-3 pl-3">
              <SelectField
                :value="year"
                :field="selectYearField"
                @input="year = $event"
                label="Year"
              ></SelectField>
            </div>
            </div>
           
            <div class="text-center py-0">
              <CheckBoxField :value="isWalkin" label="Enable Walkin" @input="isWalkin = $event"></CheckBoxField>
            </div>
            <div class="text-center pt-1">
              <v-btn :loading="api.isLoading" width="75%" rounded color="primary" dark @click="submit()">
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import TextField from "@/components/Form/TextField.vue";
import CheckBoxField from "@/components/Form/CheckBoxField.vue";
import datasetSchoolYears from "@/datasets/schoolYear";
import datasetMonths from "@/datasets/month";
import datasetYears from "@/datasets/year";
import SelectField from "@/components/Form/SelectField.vue";

import { mapState } from "vuex";
import moment from 'moment';
export default {
  components: {
    SelectField,
    TextField,
    CheckBoxField,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    name: null,
    isWalkin: false,
    nameRules: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectSchoolYearField: {
      defaultValue: 1,
      name: "School Year",
      options: datasetSchoolYears,
      optionText: "name",
      optionValue: "id",
    },
    schoolYear:1,
    selectMonthField: {
      defaultValue: 3,
      name: "Month",
      options: datasetMonths,
      optionText: "name",
      optionValue: "id",
    },
    month:3,

    selectYearField: {
      defaultValue: parseInt(moment().format('YYYY')),
      name: "Year",
      options: datasetYears,
      optionText: "",
      optionValue: "",
    },
    year:parseInt(moment().format('YYYY')),
  }),
  created() {
    //BOC:[breadcrumbs]
    this.updateBreadcrumb()
    //EOC

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/classroom/add";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageEducatorDashboard" });
    };
  },
  mounted() {
    //
  },
  watch: {
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    updateBreadcrumb() {
      this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageEducatorDashboard" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "New Classroom",
      to: { name: "PageEducatorClassroomAdd" },
      exact: true,
    });
    },
    close() {
      this.$router.push({ name: "PageEducatorDashboard" });
    },
    submit() {
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.api.params = {
            name: self.name,
            month : self.month,
            schoolYear: self.schoolYear,
            year: self.year,
            enableWalkin: self.isWalkin,
          };
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>month