<template>
    <v-checkbox v-model="inputVal"  :label="label" ></v-checkbox>
</template>

<script>
export default {
  props: ["label", "value"],
  components: {
    //
  },
  computed:{
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data: () => ({
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>